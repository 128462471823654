.backdrop {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.35);
  top: 0;
  left: 0;
  z-index: 60;
  animation: showOff 200ms ease-out;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  & > div {
    background-color: white;
  }
}

@keyframes showOff {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
