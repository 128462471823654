@import "./../../Styles/mediaqueries.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  flex-grow: 1;
  height: auto;
  font-family: "Ubuntu", sans-serif;
  padding-top: 10vh;

  @include media("<=phone") {
    padding-top: 2vh;
  }
}
.card {
  //   border: 1px solid;

  max-width: 440px;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  overflow: hidden;
  border-radius: 4px;
}
.buttonTabs {
  display: flex;
  width: 100%;

  & > a {
    flex-basis: 50%;
    position: relative;

    button {
      background-color: lawngreen;
      border-radius: 0px;
      height: 55px;
      pointer-events: none;
      color: #6e6e6e;
      background-color: #eaeaea;
      font-weight: 600;
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      height: 6px;
      width: 100%;
      color: red;
      bottom: 0;
      left: 0;
    }
  }
  .activeClass > button {
    background-color: #ffffff;
  }
}

.formWrapper {
  padding: 12px 12px;
  position: relative;
}

.fieldsWrapper {
  margin: 16px 0px;
  position: relative;
  form > div {
    margin: 1.6rem 0px;
  }

  input {
    border: none;
    border-bottom: 2px solid #dce1e6;
    border-radius: 0px;
  }
  input:focus {
    outline: 0;
    border: none;
    border-bottom: 2px solid #dce1e6;
  }

  .dropDown {
    margin: 6px 0px;
    span {
      color: #6e6e6e;
    }
  }
  .dropDown select {
    margin-top: 6px;
    max-width: 100%;
    width: 100%;
    border-radius: 6px;
    background-color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    outline: 0;
    padding: 12px 16px;
    color: var(--cloudy-clr);
    resize: vertical;
    border: none;
    border-bottom: 2px solid #dce1e6;
    border-radius: 0px;
  }
}

.submissionButtonsGroup {
  margin-top: 2rem !important;
  display: flex;
  width: 100%;
  justify-content: space-between;
  button {
    width: 32%;
    font-weight: 400;
    font-size: 16px;
    border-radius: 0px;
  }
}

.forget-p {
  display: block;
  text-align: center;
  margin: auto;
  text-decoration: none;
  color: #b9bdc0;
}
