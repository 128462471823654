.container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px 0 15px;
  position: relative;
  cursor: pointer;
}

.btn_div_s {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  position: absolute;
  background-color: white;
  box-shadow: var(--shaddow);
  top: 30px;
  left: -34px;
  border-radius: 6px;
}
.btn_div_s > button {
  display: flex;
  justify-content: center;
  align-items: center;
}
