.profileWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 12px;
}

.feeds-container {
  width: 100%;
  display: flex;
  // justify-content: center;
  margin: 12px 0px;
}

.feeds-wrapper {
  margin: auto;
  max-width: 500px;
  width: 100%;
}

.space-y > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(1.6rem * (1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(1.6rem * var(--space-y-reverse)) !important;
}
