.feeds-wrapper {
  margin: auto;
  max-width: 500px;
  width: 100%;
}
.space-y > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(1.6rem * (1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(1.6rem * var(--space-y-reverse)) !important;
}
.more-btn {
  display: flex;
  justify-content: center;
  button {
    width: 80px;
    height: 35px;
  }
}
